var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"misc-wrapper pt-0 mt-0",staticStyle:{"align-items":"initial"}},[_c('div',{staticClass:"misc-inner px-2  pt-0 mt-0"},[_c('div',{staticClass:"w-100"},[_vm._m(0),_c('p',{staticClass:"mb-2"},[_vm._v(" Looking for more information or want to try one of our paid Seocloud plans? Submit your information and an Asana representative will follow up with you as soon as possible. Have a simple question? Check out our FAQ. ")]),_c('validation-observer',{ref:"contactRules"},[_c('b-form',[_c('b-row',{},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge ",staticStyle:{"border":"1px solid #CFD3E5","border-radius":"15px","background":"#F4F7F8 !important"},attrs:{"size":"lg"}},[_c('b-input-group-prepend',{staticStyle:{"border":"transparent","border-radius":"20px"}},[_c('div',{staticClass:"input-group-text",staticStyle:{"border":"transparent","border-radius":"20px","background":"#F4F7F8 !important"}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1)]),_c('b-form-input',{staticStyle:{"border":"transparent","background":"none"},attrs:{"placeholder":"Name","state":errors.length > 0 ? false:null},model:{value:(_vm.nameContact),callback:function ($$v) {_vm.nameContact=$$v},expression:"nameContact"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge ",staticStyle:{"border":"1px solid #CFD3E5","border-radius":"15px","background":"#F4F7F8 !important"},attrs:{"size":"lg"}},[_c('b-input-group-prepend',{staticStyle:{"border":"transparent","border-radius":"20px"}},[_c('div',{staticClass:"input-group-text",staticStyle:{"border":"transparent","border-radius":"20px","background":"#F4F7F8 !important"}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1)]),_c('b-form-input',{staticStyle:{"border":"transparent","background":"none"},attrs:{"placeholder":"Email","state":errors.length > 0 ? false:null},model:{value:(_vm.emailContact),callback:function ($$v) {_vm.emailContact=$$v},expression:"emailContact"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge ",staticStyle:{"border":"1px solid #CFD3E5","border-radius":"15px","background":"#F4F7F8 !important"},attrs:{"size":"lg"}},[_c('b-input-group-prepend',{staticStyle:{"border":"transparent","border-radius":"20px"}},[_c('div',{staticClass:"input-group-text",staticStyle:{"border":"transparent","border-radius":"20px","background":"#F4F7F8 !important"}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1)]),_c('b-form-input',{staticStyle:{"border":"transparent","background":"none"},attrs:{"placeholder":"Phone number","state":errors.length > 0 ? false:null},model:{value:(_vm.phoneContact),callback:function ($$v) {_vm.phoneContact=$$v},expression:"phoneContact"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"URL","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge ",staticStyle:{"border":"1px solid #CFD3E5","border-radius":"15px","background":"#F4F7F8 !important"},attrs:{"size":"lg"}},[_c('b-input-group-prepend',{staticStyle:{"border":"transparent","border-radius":"20px"}},[_c('div',{staticClass:"input-group-text",staticStyle:{"border":"transparent","border-radius":"20px","background":"#F4F7F8 !important"}},[_c('feather-icon',{attrs:{"icon":"GlobeIcon"}})],1)]),_c('b-form-input',{staticStyle:{"border":"transparent","background":"none"},attrs:{"placeholder":"URL","state":errors.length > 0 ? false:null},model:{value:(_vm.urlContact),callback:function ($$v) {_vm.urlContact=$$v},expression:"urlContact"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('label',{staticClass:" mb-1 ml-1",staticStyle:{"color":"#656B85","font-size":"15px"}},[_vm._v("What would you like to discuss?")]),_c('b-form-textarea',{staticStyle:{"background":"#F4F7F8 !important"},attrs:{"placeholder":"Provide us some details about a project or site you would like to resolve with Seocloud.","rows":"4"},model:{value:(_vm.commentContact),callback:function ($$v) {_vm.commentContact=$$v},expression:"commentContact"}})],1),_c('b-button',{staticClass:"mt-1",staticStyle:{"color":"#A7AAB9 !important","border-radius":"23px","background":"#F0F1F7 !important","border":"none"},attrs:{"variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.validationContact.apply(null, arguments)}}},[_c('span',{staticClass:"align-middle",staticStyle:{"font-weight":"700","color":"#A7AAB9"}},[_vm._v("Submit")])])],1)],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"mb-1",staticStyle:{"font-weight":"700","color":"#232C51 !important","font-size":"32px !important"}},[_vm._v(" 👋 Get help"),_c('span',{staticClass:"text-primary"},[_vm._v(".")])])}]

export { render, staticRenderFns }