<template>
  <!-- Coming soon page-->
  <div
    class="misc-wrapper pt-0 mt-0"
    style="align-items: initial;"
  >
    <div class="misc-inner px-2  pt-0 mt-0">
      <div class="w-100">
        <h1
          class="mb-1"
          style="font-weight: 700; color: #232C51 !important; font-size: 32px !important;"
        >
          👋 Get help<span class="text-primary">.</span>
        </h1>
        <p class="mb-2">
          Looking for more information or want to try one of our paid Seocloud plans? Submit your information and an Asana representative will follow up with you as soon as possible. Have a simple question? Check out our FAQ.
        </p>
        <validation-observer
          ref="contactRules"
        >
          <b-form>
            <b-row class="">
              <b-col md="12">
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                >
                    <b-input-group
                        class="input-group-merge "
                        style="border: 1px solid #CFD3E5; border-radius: 15px; background: #F4F7F8 !important;"
                        size="lg"
                    >
                    <b-input-group-prepend style="border: transparent; border-radius: 20px;">
                        <div
                            class="input-group-text"
                            style="border: transparent; border-radius: 20px; background: #F4F7F8 !important;"
                        >
                        <feather-icon icon="UserIcon" />
                        </div>
                    </b-input-group-prepend>
                    <b-form-input
                        placeholder="Name"
                        v-model="nameContact"
                        style="border: transparent; background: none;"
                        :state="errors.length > 0 ? false:null"
                    />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge "
                    style="border: 1px solid #CFD3E5; border-radius: 15px; background: #F4F7F8 !important;"
                    size="lg"
                  >
                    <b-input-group-prepend style="border: transparent; border-radius: 20px;">
                      <div
                        class="input-group-text"
                        style="border: transparent; border-radius: 20px; background: #F4F7F8 !important;"
                      >
                        <feather-icon icon="MailIcon" />
                      </div>
                    </b-input-group-prepend>
                      <b-form-input
                        placeholder="Email"
                        style="border: transparent; background: none;"
                        v-model="emailContact"
                        :state="errors.length > 0 ? false:null"
                      />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Phone number"
                    rules="required"
                >
                    <b-input-group
                        class="input-group-merge "
                        style="border: 1px solid #CFD3E5; border-radius: 15px; background: #F4F7F8 !important;"
                        size="lg"
                    >
                    <b-input-group-prepend style="border: transparent; border-radius: 20px;">
                        <div
                            class="input-group-text"
                            style="border: transparent; border-radius: 20px; background: #F4F7F8 !important;"
                        >
                            <feather-icon icon="PhoneIcon" />
                        </div>
                    </b-input-group-prepend>
                    <b-form-input
                        placeholder="Phone number"
                        v-model="phoneContact"
                        style="border: transparent; background: none;"
                        :state="errors.length > 0 ? false:null"
                    />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
              >
                <validation-provider
                    #default="{ errors }"
                    name="URL"
                    rules="required|url"
                >
                  <b-input-group
                    class="input-group-merge "
                    style="border: 1px solid #CFD3E5; border-radius: 15px; background: #F4F7F8 !important;"
                    size="lg"
                  >
                    <b-input-group-prepend style="border: transparent; border-radius: 20px;">
                      <div
                        class="input-group-text"
                        style="border: transparent; border-radius: 20px; background: #F4F7F8 !important;"
                      >
                        <feather-icon icon="GlobeIcon" />
                      </div>
                    </b-input-group-prepend>
                      <b-form-input
                        placeholder="URL"
                        v-model="urlContact"
                        style="border: transparent; background: none;"
                        :state="errors.length > 0 ? false:null"
                      />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                class="mt-1"
              >
                <label
                  class=" mb-1 ml-1"
                  style="color: #656B85; font-size: 15px;"
                >What would you like to discuss?</label>
                <b-form-textarea
                  placeholder="Provide us some details about a project or site you would like to resolve with Seocloud."
                  rows="4"
                  v-model="commentContact"
                  style="background: #F4F7F8 !important;"
                />
              </b-col>
              <b-button
                variant="primary"
                style="color: #A7AAB9 !important; border-radius: 23px; background: #F0F1F7 !important; border: none;"
                block
                class="mt-1"
                @click.prevent="validationContact"
              >
                <span
                  class="align-middle"
                  style="font-weight: 700; color: #A7AAB9;"
                >Submit</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BFormInput, BButton, BImg, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormCheckbox, BFormRadio, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url, email } from '@validations'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { db, auth } from '@/firebase'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormRadio,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      url,
      email,
      nameContact: '',
      emailContact: '',
      phoneContact: '',
      urlContact: '',
      commentContact: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    validationContact() {
      this.$refs.contactRules.validate().then(success => {
        if(success) {
          // 
        }
      })
    }
  },
  created() {
    const user = auth.currentUser
    if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(docUser => {
        this.nameContact = docUser.data().nombre ? docUser.data().nombre : ''
        this.emailContact = docUser.data().email ? docUser.data().email : ''
        this.phoneContact = docUser.data().contact ? docUser.data().contact : ''
      })
    }

    this.urlContact = this.$route.params.url ? this.$route.params.url : ''
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<style>
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
body {
  background: rgb(24, 30, 56) !important;
}
</style>
